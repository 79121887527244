import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthApolloProvider, AuthScope } from '@digital-gov/auth-apollo-store';
import { AuthorityProvider } from 'store/authority';
import { basename } from 'store/basename';
import { rnMessenger } from 'store/rnMessenger';
import { apiUrl } from 'store/apiUrl';
import App from './App';
import reportWebVitals from './reportWebVitals';

// @ts-ignore: rnMessenger
window.rnMessenger = rnMessenger;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <HelmetProvider>
        <AuthApolloProvider authScope={AuthScope.Dashboard} apiUrl={apiUrl} redirectPathname={`${basename}/auth`}>
          <AuthorityProvider>
            <App />
          </AuthorityProvider>
        </AuthApolloProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
